<template>
    <div class="uploader-root filter-tab">
        <el-form ref="form" class="form" :model="org" :rules="rules" label-width="120px">
            <el-form-item label="区域筛选">
                <el-select v-if="provinceList.length > 0" @change="getProvinceAccessAuth" v-model="province"
                    placeholder="省份">
                    <el-option v-for="item in provinceList" :key="item[0]" :value="item[0]" :label="item[1]">
                    </el-option>
                </el-select>
                <el-select v-if="cities.length > 0" v-model="city" @change="getCityAccessAuth" placeholder="城市">
                    <el-option v-for="item in cities" :key="item[0]" :value="item[0]" :label="item[1]"></el-option>
                </el-select>
                <el-select v-if="areas.length > 0" v-model="region" @change="getOrgList" placeholder="地区">
                    <el-option v-for="item in areas" :key="item[0]" :value="item[0]" :label="item[1]"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="公司列表" prop="orgId">
                <el-select v-model="org" @change="readAccount" placeholder="请选择" value-key="orgId">
                    <el-option v-for="item in orgList" :key="item.orgId" :value="item" :label="item.orgName">
                    </el-option>
                </el-select>
                &nbsp;&nbsp;
                <el-input style="width:fit-content" v-model="org.orgName" disabled></el-input>
            </el-form-item>
            <el-form-item label="上传账户">
                <el-row :gutter="6">
                    <el-col :span="12">
                        <el-form-item prop="userId">
                            <el-input placeholder="账户名" v-model="org.userId"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item prop="password">
                            <el-input placeholder="密码" v-model="org.password"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form-item>
            <el-form-item>
                <el-button class="btn-search" type="primary" @click="submit(formRef)">绑定</el-button>
                <el-button class="btn-reset" @click="resetForm(formRef)">取消</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
import { getSubareaList, getAreaInfo } from 'network/area'
import { getOrgWithBindByArea, bindUploadAccount } from 'network/org'
import { deepCopy } from '../../assets/js/utils/utils'
export default {
    name: 'Uploader',
    props: {
        orgId: {
            type: String,
            default: ""
        },
        orgName: {
            type: String,
            default: ""
        }
    },
    data() {
        return {

            org: {},
            userId: '',
            password: '',

            rules: {
                orgId: [
                    {
                        required: true,
                        message: "请选择一个单位",
                        trigger: "change",
                    },
                ],
                userId: [
                    {
                        required: true,
                        message: "用户不能为空",
                        trigger: "change",
                    }
                ],
                password: [
                    {
                        required: true,
                        message: "密码不能为空",
                        trigger: "change",
                    }
                ]
            },
            orgList: [],
            province: '',
            city: '',
            region: '',
            provinces: [],
            cities: [],
            areas: [],
            originOrg: {},

        }
    },
    computed: {
        isBound() {
            if (this.originOrg.userId == null)
                return false
            else
                return true
        },
        formRef() {
            return this.$refs.form
        },
        provinceList() {
            return this.$store.getters['areas/provinces']
        },
        cityList() {
            return this.$store.getters['areas/cities']
        },
        areaList() {
            return this.$store.getters['areas/areas']
        },
        userAuth() {
            return this.$store.getters.getUserAuth
        }
    },
    methods: {
        submit(formIns) {
            formIns.validate(valid => {
                if (valid) {
                    let ori = this.originOrg
                    if (formIns.model.userId == ori.userId && formIns.model.password == ori.password)
                        return

                    bindUploadAccount(formIns.model).then(
                        res => {
                            if (res.status == "1")
                                ori = formIns.model
                            this.$message(res.message)
                        }, e => { console.log(e.message) }
                    )
                }
                else {
                    this.$message.warning('提交内容,校验不通过')
                    return false
                }
            })
        },
        getProvinceAccessAuth(areaId) {
            this.city = ''
            this.region = ''
            areaId = areaId.substring(0, 2)
            let cities = this.cityList.filter(a => a[0].startsWith(areaId))
            if (cities.length == 0)
                getSubareaList(areaId + '0000').then(
                    res => {
                        this.$store.dispatch('areas/addCities', res.data).then(
                            (res1) => {
                                this.cities = [...res1]
                            }, e => { console.log(e.message) })
                    },
                    e => { console.log(e.message) }
                )
            else
                this.cities = cities
        },
        getCityAccessAuth(areaId) {
            this.region = ''
            areaId = areaId.substring(0, 4)
            let areas = this.areaList.filter(a => a[0].startsWith(areaId))
            if (areas.length == 0)
                getSubareaList(areaId + '00').then(
                    res => {
                        this.$store.dispatch('areas/addAreas', res.data).then(
                            res1 => { this.areas = [...res1] }, e => { console.log(e.message) })
                    },
                    e => { console.log(e.message) }
                )
            else
                this.areas = areas
        },
        getOrgList(areaId) {
            getOrgWithBindByArea(areaId).then(
                res => {
                    this.orgList = res
                }, e => { console.log(e.message) }
            )
        },
        readAccount(val) {
            // if(val.userId!==null)
            //     this.userId=val.userId
            // if(val.password!==null)
            //     this.password=val.password
            console.log(val)
            this.originOrg = deepCopy(val)
        },
        prepare() {
            let auths = this.userAuth
            let pmap = new Map(this.provinceList)
            let p = []
            let cmap = new Map(this.cityList)
            let c = []
            let amap = new Map(this.areaList)
            let a = []
            for (let auth of auths) {
                if (auth.match(/\d{2}0000/)) {
                    if (!pmap.has(auth))
                        p.push(auth)
                }
                else if (auth.match(/\d{4}00/)) {
                    if (!cmap.has(auth))
                        c.push(auth)
                    else
                        this.cities.push([auth, cmap.get(auth)])
                }
                else if (auth.match(/\d{6}/)) {
                    if (!amap.has(auth))
                        a.push(auth)
                    else
                        this.areas.push([auth, amap.get(auth)])
                }
            }
            if (p.length > 0)
                getAreaInfo(p).then(
                    res => {
                        this.$store.dispatch('areas/addProvinces', res.data).then(res => { }, e => { console.log(e.message) })
                    },
                    e => { console.log(e.message) }
                )
            if (c.length > 0)
                getAreaInfo(c).then(
                    res => {
                        this.$store.dispatch('areas/addCities', res.data).then(res1 => { this.cities.push(...res1) }, e => { console.log(e.message) })
                    },
                    e => { console.log(e.message) }
                )
            if (a.length > 0)
                getAreaInfo(a).then(
                    res => {
                        this.$store.dispatch('areas/addAreas', res.data).then(res1 => { this.areas.push(...res1) }, e => { console.log(e.message) })
                    },
                    e => { console.log(e.message) }
                )
        }
    },
    created() {
        if (this.orgId !== "" && this.orgName !== "") {
            this.readAccount({
                userId: "",
                password: "",
                orgId: this.orgId,
                orgName: this.orgName
            })
            this.org=this.originOrg
        }
    }
}
</script>

<style lang="less" scoped>
.uploader-root {
    width: 800px;

    .el-form {
        padding-top: 20px;
        align-items: center;
    }

    .el-form-item {
        align-items: center;
        --el-text-color-placeholder: #606266;
    }
}

.filter-tab /deep/ .el-form-item__label {
  color: #f2f2f2;
  font-size: 1.5vh;
  line-height: 3vh;
}
//输入框样式
.filter-tab /deep/ .el-input__wrapper {
  height: 3.2vh;
  background-color: #0c59b9;
  color: #f2f2f2 !important;
  box-shadow: none;
}
.filter-tab /deep/ input {
  color: #f2f2f2;
}
//按钮样式
.btn-search {
  width: 6.5vh;
  height: 3vh;
  line-height: 3vh;
  background-color: transparent;
  border: 1px solid #1763FF;
  border-radius: 5px;
}
.btn-reset {
  width:6.5vh;
  height: 3vh;
  line-height: 3vh;
  background-color: transparent;
  border: 1px solid #1763FF;
  color: white;
  border-radius: 5px;
}
</style>